import React from "react"
import Gencommerce from "../images/genflow-full-text.png"
import SEO from "../components/seo"
import "../styles/index.css"

const IndexPage = () => (
  <div className='page'>
    <SEO title="Gencommerce" />
    <img src={Gencommerce} className='gencommmerce-logo'/>
  </div>
)

export default IndexPage
